import { reactive } from 'vue'

export const userStore = reactive({
  isLoggedIn: false,
  userInfo: null,
  token: null,

  setUserInfo(info) {
    this.userInfo = info
    this.isLoggedIn = true
    localStorage.setItem('userInfo', JSON.stringify(info))
  },

  setToken(token) {
    this.token = token
    localStorage.setItem('token', token)
  },

  logout() {
    this.isLoggedIn = false
    this.userInfo = null
    this.token = null
    localStorage.removeItem('userInfo')
    localStorage.removeItem('token')
  },

  checkAuth() {
    const userInfo = localStorage.getItem('userInfo')
    const token = localStorage.getItem('token')
    if (userInfo && token) {
      this.userInfo = JSON.parse(userInfo)
      this.token = token
      this.isLoggedIn = true
      return true
    }
    return false
  }
}) 