import { createRouter, createWebHistory } from 'vue-router'
import ProductDetail from '../components/ProductDetail.vue'
import LoginRegister from '../components/LoginRegister.vue'
import Dashboard from '../views/Dashboard.vue'
import { userStore } from '../store/user'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../components/Hero.vue')
  },
  {
    path: '/product/:id',
    name: 'product',
    component: ProductDetail
  },
  {
    path: '/auth',
    name: 'auth',
    component: LoginRegister
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: { requiresAuth: true }
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import('../views/Orders.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/billing',
    name: 'billing',
    component: () => import('../views/Billing.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/security',
    name: 'security',
    component: () => import('../views/Security.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/support',
    name: 'support',
    component: () => import('../views/Support.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/instances/:id',
    name: 'instance-detail',
    component: () => import('../views/InstanceDetail.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/verification',
    name: 'verification',
    component: () => import('../views/Verification.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/solutions/:type',
    name: 'solution',
    component: () => import('../views/Solution.vue')
  },
  {
    path: '/purchase',
    name: 'purchase',
    children: [
      {
        path: 'ecs',
        name: 'ecs-purchase',
        component: () => import('../views/purchase/ecs/index.vue')
      },
      {
        path: 'oss',
        name: 'oss-purchase',
        component: () => import('../views/purchase/oss/index.vue')
      },
      {
        path: 'cdn',
        name: 'cdn-purchase',
        component: () => import('../views/purchase/cdn/index.vue')
      },
      {
        path: 'slb',
        name: 'slb-purchase',
        component: () => import('../views/purchase/slb/index.vue')
      }
    ]
  },
  {
    path: '/ticket',
    name: 'ticket',
    component: () => import('../views/Ticket.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/ticket/:id',
    name: 'ticket-detail',
    component: () => import('../views/TicketDetail.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/referral',
    name: 'referral',
    component: () => import('../views/Referral.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/instances/ecs',
    name: 'ecs-instances',
    component: () => import('../views/instances/ECS.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/instances/oss',
    name: 'oss-instances',
    component: () => import('../views/instances/OSS.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/instances/cdn',
    name: 'cdn-instances',
    component: () => import('../views/instances/CDN.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/instances/slb',
    name: 'slb-instances',
    component: () => import('../views/instances/SLB.vue'),
    meta: { requiresAuth: true }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// 导航守卫
router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !userStore.isLoggedIn) {
    next('/auth')
  } else {
    next()
  }
})

export default router 