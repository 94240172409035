<template>
  <div class="login-register">
    <div class="container" :class="{ 'right-panel-active': isSignUpMode }">
      <!-- 注册面板 -->
      <div class="form-container sign-up-container">
        <form @submit.prevent="handleSignUp">
          <h1>创建账号</h1>
          <div class="social-container">
            <a href="#" class="social"><i class="fab fa-weixin"></i></a>
            <a href="#" class="social"><i class="fab fa-qq"></i></a>
            <a href="#" class="social"><i class="fab fa-weibo"></i></a>
          </div>
          <span>或使用邮箱注册</span>
          <div class="form-group">
            <input 
              type="text" 
              v-model="signUpForm.name" 
              :class="{ 'error': signUpErrors.name }"
              @focus="clearError('name')"
              placeholder="姓名" />
            <span class="error-message" v-if="signUpErrors.name">{{ signUpErrors.name }}</span>
          </div>
          <div class="form-group">
            <input 
              type="email" 
              v-model="signUpForm.email" 
              :class="{ 'error': signUpErrors.email }"
              @focus="clearError('email')"
              placeholder="电子邮箱" />
            <span class="error-message" v-if="signUpErrors.email">{{ signUpErrors.email }}</span>
          </div>
          <div class="form-group">
            <input 
              type="password" 
              v-model="signUpForm.password"
              :class="{ 'error': signUpErrors.password }"
              @focus="clearError('password')"
              placeholder="密码" />
            <span class="error-message" v-if="signUpErrors.password">{{ signUpErrors.password }}</span>
          </div>
          <button type="submit" :disabled="isLoading">
            <span v-if="!isLoading">注册</span>
            <i v-else class="fas fa-spinner fa-spin"></i>
          </button>
        </form>
      </div>

      <!-- 登录面板 -->
      <div class="form-container sign-in-container">
        <form @submit.prevent="handleSignIn">
          <h1>登录</h1>
          <div class="social-container">
            <a href="#" class="social"><i class="fab fa-weixin"></i></a>
            <a href="#" class="social"><i class="fab fa-qq"></i></a>
            <a href="#" class="social"><i class="fab fa-weibo"></i></a>
          </div>
          <span>或使用账号登录</span>
          <div class="form-group">
            <input 
              type="email" 
              v-model="signInForm.email"
              :class="{ 'error': signInErrors.email }"
              @focus="clearError('email', 'signIn')"
              placeholder="电子邮箱" />
            <span class="error-message" v-if="signInErrors.email">{{ signInErrors.email }}</span>
          </div>
          <div class="form-group">
            <input 
              type="password" 
              v-model="signInForm.password"
              :class="{ 'error': signInErrors.password }"
              @focus="clearError('password', 'signIn')"
              placeholder="密码" />
            <span class="error-message" v-if="signInErrors.password">{{ signInErrors.password }}</span>
          </div>
          <a href="#" class="forgot" @click.prevent="handleForgotPassword">忘记密码？</a>
          <button type="submit" :disabled="isLoading">
            <span v-if="!isLoading">登录</span>
            <i v-else class="fas fa-spinner fa-spin"></i>
          </button>
        </form>
      </div>

      <!-- 覆盖面板 -->
      <div class="overlay-container">
        <div class="overlay">
          <div class="overlay-panel overlay-left">
            <h1>欢迎回来！</h1>
            <p>请使用您的账号登录以继续访问我们的服务</p>
            <button class="ghost" @click="isSignUpMode = false">登录</button>
          </div>
          <div class="overlay-panel overlay-right">
            <h1>您好！</h1>
            <p>注册账号以开始使用我们的云服务</p>
            <button class="ghost" @click="isSignUpMode = true">注册</button>
          </div>
        </div>
      </div>
    </div>

    <!-- 提示消息 -->
    <div class="toast" v-if="toast.show" :class="toast.type">
      {{ toast.message }}
    </div>
  </div>
</template>

<script>
import { userStore } from '../store/user'

export default {
  name: 'LoginRegisterForm',
  data() {
    return {
      isSignUpMode: false,
      isLoading: false,
      signInForm: {
        email: '',
        password: ''
      },
      signUpForm: {
        name: '',
        email: '',
        password: ''
      },
      signInErrors: {},
      signUpErrors: {},
      toast: {
        show: false,
        message: '',
        type: 'success'
      }
    }
  },
  methods: {
    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return re.test(email)
    },

    validatePassword(password) {
      return password.length >= 6
    },

    clearError(field, form = 'signUp') {
      if (form === 'signUp') {
        this.signUpErrors[field] = ''
      } else {
        this.signInErrors[field] = ''
      }
    },

    showToast(message, type = 'success') {
      this.toast.message = message
      this.toast.type = type
      this.toast.show = true
      setTimeout(() => {
        this.toast.show = false
      }, 3000)
    },

    async handleSignIn() {
      this.signInErrors = {}
      
      // 验证邮箱
      if (!this.signInForm.email) {
        this.signInErrors.email = '请输入邮箱'
        return
      }
      if (!this.validateEmail(this.signInForm.email)) {
        this.signInErrors.email = '请输入有效的邮箱地址'
        return
      }

      // 验证密码
      if (!this.signInForm.password) {
        this.signInErrors.password = '请输入密码'
        return
      }

      try {
        this.isLoading = true
        // 模拟API调用
        await new Promise(resolve => setTimeout(resolve, 1500))
        
        // 模拟登录成功
        const mockUserInfo = {
          id: 1,
          name: '测试用户',
          email: this.signInForm.email
        }
        const mockToken = 'mock_token_' + Date.now()

        userStore.setUserInfo(mockUserInfo)
        userStore.setToken(mockToken)

        this.showToast('登录成功')
        setTimeout(() => {
          this.$router.push('/')
        }, 1000)
      } catch (error) {
        this.showToast(error.message || '登录失败', 'error')
      } finally {
        this.isLoading = false
      }
    },

    async handleSignUp() {
      this.signUpErrors = {}
      
      // 验证姓名
      if (!this.signUpForm.name) {
        this.signUpErrors.name = '请输入姓名'
        return
      }

      // 验证邮箱
      if (!this.signUpForm.email) {
        this.signUpErrors.email = '请输入邮箱'
        return
      }
      if (!this.validateEmail(this.signUpForm.email)) {
        this.signUpErrors.email = '请输入有效的邮箱地址'
        return
      }

      // 验证密码
      if (!this.signUpForm.password) {
        this.signUpErrors.password = '请输入密码'
        return
      }
      if (!this.validatePassword(this.signUpForm.password)) {
        this.signUpErrors.password = '密码长度至少6位'
        return
      }

      try {
        this.isLoading = true
        // 模拟API调用
        await new Promise(resolve => setTimeout(resolve, 1500))
        
        this.showToast('注册成功')
        this.isSignUpMode = false
      } catch (error) {
        this.showToast(error.message || '注册失败', 'error')
      } finally {
        this.isLoading = false
      }
    },

    handleForgotPassword() {
      if (!this.signInForm.email) {
        this.signInErrors.email = '请先输入邮箱地址'
        return
      }
      this.showToast('重置密码链接已发送到您的邮箱')
    }
  }
}
</script>

<style scoped>
.login-register {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #f8f9fa;
  font-family: 'Arial', sans-serif;
}

.container {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  position: relative;
  overflow: hidden;
  width: 768px;
  max-width: 100%;
  min-height: 580px;
}

.form-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.sign-in-container {
  left: 0;
  width: 50%;
  z-index: 2;
}

.sign-up-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}

.container.right-panel-active .sign-in-container {
  transform: translateX(100%);
}

.container.right-panel-active .sign-up-container {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
}

form {
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
}

h1 {
  font-weight: bold;
  margin: 0;
  color: #2c3e50;
}

span {
  font-size: 12px;
  color: #666;
  margin: 15px 0;
}

input {
  background-color: #f6f6f6;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
  border-radius: 5px;
  font-size: 14px;
  transition: all 0.3s ease;
}

input:focus {
  background-color: #fff;
  box-shadow: 0 0 0 2px #42b983;
  outline: none;
}

button {
  border-radius: 20px;
  border: 1px solid #42b983;
  background-color: #42b983;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  cursor: pointer;
}

button:active {
  transform: scale(0.95);
}

button.ghost {
  background-color: transparent;
  border-color: #FFFFFF;
}

.social-container {
  margin: 20px 0;
}

.social-container a {
  border: 1px solid #DDDDDD;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
  text-decoration: none;
  color: #333;
  transition: all 0.3s ease;
}

.social-container a:hover {
  border-color: #42b983;
  color: #42b983;
}

.forgot {
  color: #666;
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0;
  transition: color 0.3s ease;
}

.forgot:hover {
  color: #42b983;
}

.overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.container.right-panel-active .overlay-container {
  transform: translateX(-100%);
}

.overlay {
  background: linear-gradient(to right, #42b983, #2c3e50);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #FFFFFF;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.container.right-panel-active .overlay {
  transform: translateX(50%);
}

.overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.overlay-left {
  transform: translateX(-20%);
}

.container.right-panel-active .overlay-left {
  transform: translateX(0);
}

.overlay-right {
  right: 0;
  transform: translateX(0);
}

.container.right-panel-active .overlay-right {
  transform: translateX(20%);
}

.overlay-panel h1 {
  color: white;
  margin-bottom: 20px;
}

.overlay-panel p {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}

@media (max-width: 768px) {
  .container {
    min-height: 800px;
    width: 90%;
  }

  .form-container {
    width: 100%;
  }

  .overlay-container {
    display: none;
  }

  .sign-in-container,
  .sign-up-container {
    width: 100%;
  }

  .sign-up-container {
    transform: translateX(100%);
  }

  .container.right-panel-active .sign-in-container {
    transform: translateX(-100%);
  }

  .container.right-panel-active .sign-up-container {
    transform: translateX(0);
  }
}

.form-group {
  width: 100%;
  margin-bottom: 15px;
}

.error {
  border-color: #ff4444 !important;
  background-color: #fff2f2 !important;
}

.error-message {
  color: #ff4444;
  font-size: 12px;
  margin-top: 5px;
  display: block;
  text-align: left;
}

.toast {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 15px 25px;
  border-radius: 5px;
  color: white;
  z-index: 1000;
  animation: slideIn 0.3s ease;
}

.toast.success {
  background-color: #42b983;
}

.toast.error {
  background-color: #ff4444;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.fa-spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style> 