<template>
  <div class="dashboard">
    <div class="dashboard-container">
      <DashboardSidebar />
      <div class="main-content">
        <DashboardHeader title="控制台" @toggle-sidebar="toggleSidebar" />
        <div class="content-area">
          <div class="content-wrapper">
            <div class="left-column">
              <div class="account-info-card card">
                <div class="account-balance">
                  <div class="balance-item">
                    <div class="balance-header">
                      <h3>账户余额 (元)</h3>
                      <div class="balance-amount">
                        <span class="currency">¥</span>
                        <span class="amount">1,234.56</span>
                      </div>
                      <div class="balance-tip">
                        <i class="fas fa-exclamation-circle"></i>
                        余额预警：¥500
                      </div>
                    </div>
                  </div>

                  <div class="balance-divider"></div>

                  <div class="balance-item">
                    <div class="balance-header">
                      <h3>本月消费</h3>
                      <div class="balance-amount">
                        <span class="currency">¥</span>
                        <span class="amount">567.89</span>
                      </div>
                      <div class="balance-trend">
                        <i class="fas fa-arrow-up"></i>
                        较上月增长 12.5%
                      </div>
                    </div>
                  </div>

                  <div class="balance-actions">
                    <button class="recharge-btn" @click="showRechargeModal = true">
                      <i class="fas fa-plus-circle"></i>
                      充值
                    </button>
                    <router-link to="/billing" class="details-link">
                      <i class="fas fa-chart-line"></i>
                      账单明细
                    </router-link>
                  </div>
                </div>
              </div>

              <div class="support-card card">
                <div class="support-info">
                  <div class="support-avatar">
                    <img src="https://via.placeholder.com/100" alt="客服头像">
                    <span class="status-dot"></span>
                  </div>
                  <div class="support-details">
                    <h3>专属客服：王小云</h3>
                    <p>在线时间：周一至周五 9:00-18:00</p>
                    <div class="support-actions">
                      <button class="contact-btn">
                        <i class="fas fa-comment-dots"></i>
                        在线咨询
                      </button>
                      <button class="contact-btn secondary">
                        <i class="fas fa-phone-alt"></i>
                        电话联系
                      </button>
                    </div>
                  </div>
                </div>
                <div class="quick-actions">
                  <div class="action-item">
                    <i class="fas fa-history"></i>
                    <span>服务记录</span>
                  </div>
                  <div class="action-item">
                    <i class="fas fa-star"></i>
                    <span>满意度评价</span>
                  </div>
                  <div class="action-item">
                    <i class="fas fa-calendar-alt"></i>
                    <span>预约服务</span>
                  </div>
                </div>
              </div>

              <div class="card recommendation-section">
                <div class="section-header">
                  <h3>为您推荐</h3>
                  <button class="view-all">查看全部</button>
                </div>
                <div class="recommendations">
                  <div class="recommendation-card" v-for="(item, index) in recommendations" :key="index">
                    <div class="recommendation-icon" :style="{ background: item.iconBg }">
                      <i :class="item.icon" :style="{ color: item.iconColor }"></i>
                    </div>
                    <div class="recommendation-content">
                      <h4>{{ item.title }}</h4>
                      <p>{{ item.description }}</p>
                      <div class="recommendation-footer">
                        <span class="price" v-if="item.price">¥{{ item.price }}起</span>
                        <button class="try-btn">立即体验</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="right-column">
              <div class="overview-cards">
                <div class="card">
                  <div class="card-icon" style="background: rgba(66, 185, 131, 0.1)">
                    <i class="fas fa-server" style="color: #42b983"></i>
                  </div>
                  <div class="card-info">
                    <h3>实例状态</h3>
                    <div class="instance-stats">
                      <span class="stat-item">
                        <i class="fas fa-play-circle" style="color: #42b983"></i>
                        运行中: 8
                      </span>
                      <span class="stat-item">
                        <i class="fas fa-stop-circle" style="color: #ff4444"></i>
                        已停止: 4
                      </span>
                    </div>
                  </div>
                </div>

                <div class="card">
                  <div class="card-icon" style="background: rgba(64, 158, 255, 0.1)">
                    <i class="fas fa-microchip" style="color: #409eff"></i>
                  </div>
                  <div class="card-info">
                    <h3>资源用量</h3>
                    <div class="resource-usage">
                      <div class="usage-bar">
                        <div class="bar-label">CPU</div>
                        <div class="bar-wrapper">
                          <div class="bar" :style="{ width: '65%' }"></div>
                          <span>65%</span>
                        </div>
                      </div>
                      <div class="usage-bar">
                        <div class="bar-label">内存</div>
                        <div class="bar-wrapper">
                          <div class="bar" :style="{ width: '78%' }"></div>
                          <span>78%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card">
                  <div class="card-icon" style="background: rgba(255, 153, 0, 0.1)">
                    <i class="fas fa-exclamation-triangle" style="color: #ff9900"></i>
                  </div>
                  <div class="card-info">
                    <h3>告警信息</h3>
                    <div class="alert-stats">
                      <span class="stat-item warning">
                        <i class="fas fa-exclamation-circle"></i>
                        待处理: 2
                      </span>
                      <span class="stat-item success">
                        <i class="fas fa-check-circle"></i>
                        已处理: 5
                      </span>
                    </div>
                  </div>
                </div>

                <div class="card">
                  <div class="card-icon" style="background: rgba(255, 87, 34, 0.1)">
                    <i class="fas fa-shield-alt" style="color: #ff5722"></i>
                  </div>
                  <div class="card-info">
                    <h3>安全状态</h3>
                    <div class="security-stats">
                      <div class="security-item">
                        <i class="fas fa-check-circle" style="color: #42b983"></i>
                        DDoS防护：正常
                      </div>
                      <div class="security-item">
                        <i class="fas fa-shield-alt" style="color: #409eff"></i>
                        防火墙：已开启
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="recent-activities card">
                <div class="section-header">
                  <h3>最近动态</h3>
                  <button class="view-all">查看全部</button>
                </div>
                <div class="activities-list">
                  <div class="activity-item" v-for="(activity, index) in recentActivities" :key="index">
                    <div class="activity-icon" :class="activity.type">
                      <i :class="activity.icon"></i>
                    </div>
                    <div class="activity-content">
                      <div class="activity-title">{{ activity.title }}</div>
                      <div class="activity-time">{{ activity.time }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardSidebar from '../components/DashboardSidebar.vue'
import DashboardHeader from '../components/DashboardHeader.vue'

export default {
  name: 'DashboardPanel',
  components: {
    DashboardSidebar,
    DashboardHeader
  },
  data() {
    return {
      recentOrders: [
        {
          id: 'ORD20240101001',
          product: '云服务器 ECS',
          amount: '299.00',
          status: 'success',
          statusText: '已完成',
          time: '2024-01-01 12:00:00'
        },
        {
          id: 'ORD20240102002',
          product: '对象存储 OSS',
          amount: '99.00',
          status: 'pending',
          statusText: '处理中',
          time: '2024-01-02 14:30:00'
        }
      ],
      showRechargeModal: false,
      rechargeAmount: '',
      selectedPayment: '',
      quickAmounts: [100, 500, 1000, 2000],
      paymentMethods: [
        { id: 'alipay', name: '支付宝', icon: 'fab fa-alipay' },
        { id: 'wechat', name: '微信支付', icon: 'fab fa-weixin' },
        { id: 'union', name: '银联支付', icon: 'fas fa-credit-card' }
      ],
      recentActivities: [
        {
          type: 'warning',
          icon: 'fas fa-exclamation-circle',
          title: 'CPU使用率超过80%',
          time: '10分钟前'
        },
        {
          type: 'success',
          icon: 'fas fa-check-circle',
          title: '实例自动扩容成功',
          time: '30分钟前'
        },
        {
          type: 'info',
          icon: 'fas fa-info-circle',
          title: '系统例行维护通知',
          time: '2小时前'
        },
        {
          type: 'primary',
          icon: 'fas fa-server',
          title: '新建云服务器实例',
          time: '4小时前'
        }
      ],
      isSidebarCollapsed: false,
      recommendations: [
        {
          title: '高性能云服务器',
          description: '新一代云服务器，提供更强劲的计算能力和更优惠的价格',
          icon: 'fas fa-server',
          iconBg: 'rgba(66, 185, 131, 0.1)',
          iconColor: '#42b983',
          price: '99'
        },
        {
          title: '对象存储服务',
          description: '安全可靠的云存储服务，满足各种数据存储需求',
          icon: 'fas fa-database',
          iconBg: 'rgba(64, 158, 255, 0.1)',
          iconColor: '#409eff',
          price: '49'
        },
        {
          title: 'CDN加速服务',
          description: '全球节点分发，提供快速、稳定的内容分发服务',
          icon: 'fas fa-globe',
          iconBg: 'rgba(255, 153, 0, 0.1)',
          iconColor: '#ff9900',
          price: '199'
        }
      ]
    }
  },
  methods: {
    toggleSidebar() {
      this.isSidebarCollapsed = !this.isSidebarCollapsed
    }
  }
}
</script>

<style scoped>
.dashboard {
  min-height: 100vh;
  background: #f8f9fa;
}

.dashboard-container {
  display: flex;
  min-height: 100vh;
}

/* 侧边栏 */
.sidebar {
  width: 260px;
  background: white;
  box-shadow: 2px 0 8px rgba(0,0,0,0.05);
  transition: all 0.3s ease;
}

.sidebar-collapsed {
  width: 80px;
}

.sidebar-header {
  padding: 1.5rem;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user-profile {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar i {
  font-size: 1.5rem;
  color: #666;
}

.user-info h3 {
  margin: 0;
  font-size: 1rem;
  color: #2c3e50;
}

.user-info p {
  margin: 0;
  font-size: 0.8rem;
  color: #666;
}

.collapse-btn {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  padding: 0.5rem;
  transition: all 0.3s ease;
}

.sidebar-collapsed .collapse-btn i {
  transform: rotate(180deg);
}

.sidebar-menu {
  padding: 1rem 0;
}

.menu-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem 1.5rem;
  color: #666;
  text-decoration: none;
  transition: all 0.3s ease;
}

.menu-item:hover {
  background: rgba(66, 185, 131, 0.1);
  color: #42b983;
}

.menu-item.active {
  background: #42b983;
  color: white;
}

.menu-item i {
  font-size: 1.2rem;
}

/* 主内容区样式 */
.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.header-actions {
  display: flex;
  gap: 1rem;
}

.search-box {
  position: relative;
}

.search-box input {
  padding: 0.5rem 1rem 0.5rem 2.5rem;
  border: 1px solid #eee;
  border-radius: 25px;
  outline: none;
  width: 200px;
  transition: all 0.3s ease;
}

.search-box i {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
}

.notifications {
  position: relative;
  padding: 0.5rem;
  cursor: pointer;
}

.badge {
  position: absolute;
  top: 0;
  right: 0;
  background: #ff4444;
  color: white;
  font-size: 0.7rem;
  padding: 0.2rem 0.4rem;
  border-radius: 10px;
}

/* 概览卡片样式 */
.overview-cards {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.overview-cards .card {
  display: flex;
  align-items: center;
  padding: 1.5rem;
  gap: 1rem;
}

.card-icon {
  width: 48px;
  height: 48px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.card-info {
  flex: 1;
  min-width: 0;
}

.card-info h3 {
  margin: 0;
  font-size: 0.9rem;
  color: #666;
}

.number {
  margin: 0.5rem 0;
  font-size: 1.2rem;
  font-weight: bold;
  color: #2c3e50;
}

.trend {
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.trend.up { color: #42b983; }
.trend.down { color: #ff4444; }
.trend.stable { color: #ff9900; }

/* 图表区域样式 */
.charts-container {
  margin-bottom: 2rem;
}

.chart-card {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 4px 12px rgba(0,0,0,0.05);
}

.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.chart-actions select {
  padding: 0.5rem;
  border: 1px solid #eee;
  border-radius: 5px;
  outline: none;
}

.placeholder-chart {
  height: 300px;
  background: #f8f9fa;
  border-radius: 8px;
}

/* 订单表格样式 */
.recent-orders {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 4px 12px rgba(0,0,0,0.05);
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.view-all {
  background: none;
  border: none;
  color: #42b983;
  cursor: pointer;
}

.table-container {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 1rem;
  text-align: left;
  border-bottom: 1px solid #eee;
}

th {
  color: #666;
  font-weight: 500;
}

.status {
  padding: 0.3rem 0.8rem;
  border-radius: 15px;
  font-size: 0.9rem;
}

.status.success {
  background: rgba(66, 185, 131, 0.1);
  color: #42b983;
}

.status.pending {
  background: rgba(255, 153, 0, 0.1);
  color: #ff9900;
}

.status.failed {
  background: rgba(255, 68, 68, 0.1);
  color: #ff4444;
}

.action-btn {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  padding: 0.5rem;
}

/* 添加专属客服卡片样式 */
.support-card {
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  background: linear-gradient(to right, #ffffff, #f8f9fa);
  border-left: 4px solid #42b983;
}

.support-info {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.support-avatar {
  position: relative;
}

.support-avatar img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #fff;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

.status-dot {
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 12px;
  height: 12px;
  background: #42b983;
  border-radius: 50%;
  border: 2px solid #fff;
  animation: pulse 2s infinite;
}

.support-details h3 {
  color: #2c3e50;
  margin-bottom: 0.5rem;
}

.support-details p {
  color: #666;
  margin-bottom: 1rem;
}

.support-actions {
  display: flex;
  gap: 1rem;
}

.contact-btn {
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 25px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
}

.contact-btn:not(.secondary) {
  background: #42b983;
  color: white;
}

.contact-btn.secondary {
  background: transparent;
  border: 1px solid #42b983;
  color: #42b983;
}

.contact-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(66, 185, 131, 0.2);
}

.quick-actions {
  display: flex;
  gap: 2rem;
  padding-left: 2rem;
  border-left: 1px solid #eee;
}

.action-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.action-item i {
  font-size: 1.5rem;
  color: #42b983;
  transition: all 0.3s ease;
}

.action-item span {
  color: #666;
  font-size: 0.9rem;
}

.action-item:hover {
  transform: translateY(-2px);
}

.action-item:hover i {
  transform: scale(1.1);
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(66, 185, 131, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(66, 185, 131, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(66, 185, 131, 0);
  }
}

@media (max-width: 968px) {
  .support-card {
    flex-direction: column;
    gap: 2rem;
  }

  .quick-actions {
    padding-left: 0;
    border-left: none;
    border-top: 1px solid #eee;
    padding-top: 2rem;
    width: 100%;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .dashboard-container {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 100;
    height: auto;
  }

  .sidebar-header {
    display: none;
  }

  .sidebar-menu {
    display: flex;
    padding: 0;
  }

  .menu-item {
    flex: 1;
    padding: 1rem;
    justify-content: center;
  }

  .menu-item span {
    display: none;
  }

  .main-content {
    padding: 1rem;
    margin-bottom: 60px;
  }

  .overview-cards {
    grid-template-columns: 1fr;
  }

  .support-info {
    flex-direction: column;
    text-align: center;
  }

  .support-actions {
    flex-direction: column;
  }

  .quick-actions {
    flex-direction: column;
    gap: 1.5rem;
  }

  .action-item {
    flex-direction: row;
    justify-content: center;
  }
}

.usage-chart {
  height: 400px;
  width: 100%;
}

.chart-card {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 4px 12px rgba(0,0,0,0.05);
}

.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.chart-actions select {
  padding: 0.5rem 1rem;
  border: 1px solid #eee;
  border-radius: 5px;
  outline: none;
  color: #666;
  cursor: pointer;
  transition: all 0.3s ease;
}

.chart-actions select:hover {
  border-color: #42b983;
}

@media (max-width: 768px) {
  .usage-chart {
    height: 300px;
  }
}

/* 更新账户信息卡片样式 */
.account-info-card {
  margin-bottom: 0.5rem;
  background: white;
}

.account-balance {
  display: flex;
  align-items: center;
  padding: 1rem;
  gap: 0;
}

.balance-item {
  flex: 1;
  min-width: 0;
  padding: 0 1rem;
}

.balance-divider {
  width: 1px;
  height: 40px;
  background: #eee;
  margin: 0;
}

.balance-header {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.balance-header h3 {
  color: #666;
  font-size: 0.9rem;
  margin: 0;
}

.balance-amount {
  display: flex;
  align-items: baseline;
  gap: 0.2rem;
}

.currency {
  font-size: 0.9rem;
  color: #2c3e50;
}

.amount {
  font-size: 1.6rem;
  font-weight: bold;
  color: #2c3e50;
}

.balance-tip, .balance-trend {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  color: #666;
  font-size: 0.8rem;
}

.balance-tip i {
  color: #ff9900;
}

.balance-trend {
  color: #42b983;
}

.balance-trend i {
  font-size: 0.8rem;
}

.balance-actions {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  padding-left: 1rem;
}

.recharge-btn, .details-link {
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  transition: all 0.3s ease;
  white-space: nowrap;
}

.recharge-btn {
  background: #42b983;
  color: white;
  border: none;
  cursor: pointer;
}

.recharge-btn:hover {
  background: #3aa876;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(66, 185, 131, 0.2);
}

.details-link {
  border: 1px solid #42b983;
  color: #42b983;
  text-decoration: none;
}

.details-link:hover {
  background: rgba(66, 185, 131, 0.1);
}

/* 响应式布局调整 */
@media (max-width: 1200px) {
  .account-balance {
    flex-wrap: wrap;
    gap: 0.8rem;
  }

  .balance-item {
    flex: 1 1 calc(50% - 1.6rem);
    min-width: 200px;
  }

  .balance-divider {
    display: none;
  }

  .balance-actions {
    width: 100%;
    padding: 0.8rem 1rem 0;
    border-top: 1px solid #eee;
    justify-content: flex-end;
  }
}

@media (max-width: 768px) {
  .balance-item {
    flex: 1 1 100%;
  }

  .balance-actions {
    flex-direction: column;
    align-items: stretch;
  }

  .recharge-btn,
  .details-link {
    width: 100%;
    justify-content: center;
  }
}

/* 充值弹窗样式 */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.recharge-modal {
  background: white;
  border-radius: 12px;
  width: 90%;
  max-width: 500px;
  animation: slideIn 0.3s ease;
}

.modal-header {
  padding: 1.5rem;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-btn {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  font-size: 1.2rem;
}

.modal-body {
  padding: 1.5rem;
}

.amount-input {
  position: relative;
  margin-bottom: 1.5rem;
}

.currency-symbol {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: #2c3e50;
  font-size: 1.2rem;
}

.amount-input input {
  width: 100%;
  padding: 1rem 1rem 1rem 2.5rem;
  border: 1px solid #eee;
  border-radius: 8px;
  font-size: 1.2rem;
  outline: none;
}

.quick-amounts {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.amount-btn {
  padding: 0.8rem;
  border: 1px solid #eee;
  border-radius: 8px;
  background: white;
  cursor: pointer;
  transition: all 0.3s ease;
}

.amount-btn.active {
  border-color: #42b983;
  background: rgba(66, 185, 131, 0.1);
  color: #42b983;
}

.payment-methods {
  margin-bottom: 1.5rem;
}

.payment-methods h4 {
  margin-bottom: 1rem;
  color: #2c3e50;
}

.method-options {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.method-option {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
  border: 1px solid #eee;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.method-option input {
  display: none;
}

.method-option i {
  font-size: 1.5rem;
  color: #666;
}

.method-option input:checked + i {
  color: #42b983;
}

.confirm-btn {
  width: 100%;
  padding: 1rem;
  background: #42b983;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.confirm-btn:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.confirm-btn:not(:disabled):hover {
  background: #3aa876;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(66, 185, 131, 0.2);
}

@keyframes slideIn {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .account-balance {
    flex-direction: column;
    gap: 1.5rem;
  }

  .balance-actions {
    width: 100%;
  }

  .recharge-btn,
  .details-link {
    flex: 1;
    justify-content: center;
  }

  .account-stats {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .quick-amounts {
    grid-template-columns: repeat(2, 1fr);
  }

  .method-options {
    grid-template-columns: 1fr;
  }
}

.content-wrapper {
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
}

.left-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  min-width: 0;
}

.right-column {
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.overview-cards {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.overview-cards .card {
  display: flex;
  align-items: center;
  padding: 1.5rem;
  gap: 1rem;
}

.card-icon {
  width: 48px;
  height: 48px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.card-info {
  flex: 1;
  min-width: 0;
}

.card-info h3 {
  margin: 0;
  font-size: 0.9rem;
  color: #666;
}

.number {
  margin: 0.5rem 0;
  font-size: 1.2rem;
  font-weight: bold;
  color: #2c3e50;
}

.trend {
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

/* 响应式布局 */
@media (max-width: 1200px) {
  .content-wrapper {
    flex-direction: column;
    gap: 0.5rem;
  }

  .right-column {
    width: 100%;
  }

  .overview-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 0.5rem;
  }
}

@media (max-width: 768px) {
  .content-wrapper {
    gap: 0.5rem;
  }

  .overview-cards {
    grid-template-columns: 1fr;
  }

  .card {
    margin-bottom: 0.5rem;
  }
}

/* 添加新的样式 */
.instance-stats {
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
}

.stat-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
}

.resource-usage {
  margin-top: 0.5rem;
}

.usage-bar {
  margin-bottom: 0.5rem;
}

.bar-label {
  font-size: 0.8rem;
  color: #666;
  margin-bottom: 0.2rem;
}

.bar-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.bar {
  height: 6px;
  background: #42b983;
  border-radius: 3px;
  transition: width 0.3s ease;
}

.bar-wrapper span {
  font-size: 0.8rem;
  color: #666;
}

.alert-stats {
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
}

.security-stats {
  margin-top: 0.5rem;
}

.security-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
  margin-bottom: 0.3rem;
}

.activities-list {
  margin-top: 1rem;
}

.activity-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem 0;
  border-bottom: 1px solid #eee;
}

.activity-item:last-child {
  border-bottom: none;
}

.activity-icon {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.activity-icon.warning {
  background: rgba(255, 153, 0, 0.1);
  color: #ff9900;
}

.activity-icon.success {
  background: rgba(66, 185, 131, 0.1);
  color: #42b983;
}

.activity-icon.info {
  background: rgba(64, 158, 255, 0.1);
  color: #409eff;
}

.activity-icon.primary {
  background: rgba(103, 58, 183, 0.1);
  color: #673ab7;
}

.activity-content {
  flex: 1;
}

.activity-title {
  color: #2c3e50;
  margin-bottom: 0.3rem;
}

.activity-time {
  font-size: 0.8rem;
  color: #666;
}

.stat-item.warning {
  color: #ff9900;
}

.stat-item.success {
  color: #42b983;
}

/* 添加推荐区域样式 */
.recommendation-section {
  padding: 1.5rem;
}

.recommendations {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-top: 1rem;
}

.recommendation-card {
  display: flex;
  gap: 1rem;
  padding: 1.5rem;
  border: 1px solid #eee;
  border-radius: 12px;
  transition: all 0.3s ease;
}

.recommendation-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0,0,0,0.05);
  border-color: #42b983;
}

.recommendation-icon {
  width: 48px;
  height: 48px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.recommendation-icon i {
  font-size: 1.5rem;
}

.recommendation-content {
  flex: 1;
}

.recommendation-content h4 {
  margin: 0 0 0.5rem 0;
  color: #2c3e50;
}

.recommendation-content p {
  margin: 0;
  color: #666;
  font-size: 0.9rem;
  line-height: 1.5;
}

.recommendation-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.price {
  color: #ff6b6b;
  font-weight: bold;
}

.try-btn {
  padding: 0.5rem 1rem;
  background: transparent;
  border: 1px solid #42b983;
  color: #42b983;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.try-btn:hover {
  background: #42b983;
  color: white;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.view-all {
  background: none;
  border: none;
  color: #42b983;
  cursor: pointer;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.view-all:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .recommendations {
    grid-template-columns: 1fr;
  }

  .recommendation-card {
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
  }

  .recommendation-icon {
    margin: 0 auto;
  }

  .recommendation-footer {
    flex-direction: column;
    gap: 1rem;
  }

  .try-btn {
    width: 100%;
  }
}
</style> 