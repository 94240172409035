<template>
  <div class="sidebar" :class="{ 'sidebar-collapsed': isSidebarCollapsed }">
    <div class="sidebar-header">
      <div class="logo">
        <i class="fas fa-cloud"></i>
        <span v-if="!isSidebarCollapsed">CloudService</span>
      </div>
      <button class="collapse-btn" @click="toggleSidebar">
        <i class="fas fa-chevron-left"></i>
      </button>
    </div>
    <div class="sidebar-menu">
      <template v-for="item in dashboardMenuItems" :key="item.path">
        <!-- 有子菜单的项目 -->
        <div v-if="item.children" class="menu-group">
          <div 
            class="menu-item parent"
            :class="{ active: isParentActive(item) }"
            @click="toggleSubmenu(item)">
            <i :class="item.icon"></i>
            <span v-if="!isSidebarCollapsed">{{ item.name }}</span>
            <i 
              v-if="!isSidebarCollapsed" 
              class="fas fa-chevron-down submenu-arrow"
              :class="{ 'rotated': openSubmenus.includes(item.path) }">
            </i>
          </div>
          <div 
            class="submenu" 
            :class="{ 
              'show': openSubmenus.includes(item.path),
              'collapsed': isSidebarCollapsed 
            }">
            <router-link 
              v-for="child in item.children"
              :key="child.path"
              :to="child.path"
              class="menu-item child"
              :class="{ active: currentPath === child.path }">
              <i :class="child.icon"></i>
              <span v-if="!isSidebarCollapsed">{{ child.name }}</span>
            </router-link>
          </div>
        </div>

        <!-- 无子菜单的项目 -->
        <router-link 
          v-else
          :to="item.path"
          class="menu-item"
          :class="{ active: currentPath === item.path }">
          <i :class="item.icon"></i>
          <span v-if="!isSidebarCollapsed">{{ item.name }}</span>
        </router-link>
      </template>
    </div>
  </div>
</template>

<script>
import { dashboardMenuItems } from '../config/menu'

export default {
  name: 'DashboardSidebar',
  data() {
    return {
      isSidebarCollapsed: false,
      dashboardMenuItems,
      openSubmenus: []
    }
  },
  computed: {
    currentPath() {
      return this.$route.path
    }
  },
  methods: {
    toggleSidebar() {
      this.isSidebarCollapsed = !this.isSidebarCollapsed
    },
    toggleSubmenu(item) {
      const index = this.openSubmenus.indexOf(item.path)
      if (index === -1) {
        this.openSubmenus.push(item.path)
      } else {
        this.openSubmenus.splice(index, 1)
      }
    },
    isParentActive(item) {
      return item.children?.some(child => this.currentPath === child.path)
    }
  }
}
</script>

<style scoped>
.logo {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: #2c3e50;
}

.logo i {
  font-size: 1.5rem;
  color: #42b983;
}

.sidebar-header {
  padding: 1.2rem;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.collapse-btn {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  padding: 0.5rem;
  transition: all 0.3s ease;
}

.sidebar-collapsed .collapse-btn i {
  transform: rotate(180deg);
}

.sidebar-menu {
  padding: 1rem 0;
}

.menu-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.8rem 1.5rem;
  color: #666;
  text-decoration: none;
  transition: all 0.3s ease;
}

.menu-item:hover {
  background: rgba(66, 185, 131, 0.1);
  color: #42b983;
}

.menu-item.active {
  background: #42b983;
  color: white;
}

.menu-item i {
  font-size: 1.2rem;
  min-width: 1.2rem;
  text-align: center;
}

@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 100;
    height: auto;
  }

  .sidebar-header {
    display: none;
  }

  .sidebar-menu {
    display: flex;
    padding: 0;
    background: white;
    box-shadow: 0 -2px 10px rgba(0,0,0,0.1);
  }

  .menu-item {
    flex: 1;
    padding: 0.8rem;
    justify-content: center;
  }

  .menu-item span {
    display: none;
  }

  .menu-item i {
    font-size: 1.4rem;
  }
}

.menu-group {
  margin-bottom: 0.5rem;
}

.menu-item.parent {
  position: relative;
  cursor: pointer;
}

.submenu-arrow {
  position: absolute;
  right: 1rem;
  transition: transform 0.3s ease;
}

.submenu-arrow.rotated {
  transform: rotate(180deg);
}

.submenu {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.submenu.show {
  max-height: 500px;
}

.submenu.collapsed {
  position: absolute;
  left: 100%;
  top: 0;
  background: white;
  box-shadow: 4px 0 8px rgba(0,0,0,0.1);
  border-radius: 0 8px 8px 0;
  z-index: 100;
  display: none;
}

.menu-item.parent:hover .submenu.collapsed {
  display: block;
}

.menu-item.child {
  padding-left: 3rem;
  font-size: 0.9rem;
}

.menu-item.child:hover {
  background: rgba(66, 185, 131, 0.1);
}

@media (max-width: 768px) {
  .submenu {
    position: static;
    box-shadow: none;
  }

  .menu-item.child {
    padding-left: 1rem;
  }
}
</style> 