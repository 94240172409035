<template>
  <div class="cloud-computing">
    <nav class="navbar" :class="{ 'navbar-scrolled': isScrolled }" v-if="showNavbar">
      <div class="nav-container">
        <router-link to="/" class="logo">
          <i class="fas fa-cloud"></i>
          CloudService
        </router-link>
        <div class="nav-links" :class="{ 'nav-active': isMenuOpen }">
          <div class="nav-item dropdown parent-menu">
            <a href="#" class="dropdown-toggle">
              <i class="fas fa-cube"></i>
              产品服务
              <i class="fas fa-chevron-down arrow"></i>
            </a>
            <div class="dropdown-menu">
              <div class="menu-header">产品服务</div>
              <router-link to="/purchase/ecs" class="dropdown-item">
                <i class="fas fa-server"></i>
                <div class="item-content">
                  <div class="item-title">云服务器 ECS</div>
                  <div class="item-desc">稳定、安全、弹性的计算服务</div>
                </div>
              </router-link>
              <router-link to="/purchase/oss" class="dropdown-item">
                <i class="fas fa-database"></i>
                <div class="item-content">
                  <div class="item-title">对象存储 OSS</div>
                  <div class="item-desc">海量、安全、低成本的存储服务</div>
                </div>
              </router-link>
              <router-link to="/purchase/cdn" class="dropdown-item">
                <i class="fas fa-network-wired"></i>
                <div class="item-content">
                  <div class="item-title">CDN加速服务</div>
                  <div class="item-desc">全球节点分发，提供快速、稳定的内容分发服务</div>
                </div>
              </router-link>
              <router-link to="/purchase/ddos" class="dropdown-item">
                <i class="fas fa-shield-alt"></i>
                <div class="item-content">
                  <div class="item-title">DDoS防护</div>
                  <div class="item-desc">大规模分布式防护系统</div>
                </div>
              </router-link>
            </div>
          </div>

          <div class="nav-item dropdown parent-menu">
            <a href="#" class="dropdown-toggle">
              <i class="fas fa-lightbulb"></i>
              解决方案
              <i class="fas fa-chevron-down arrow"></i>
            </a>
            <div class="dropdown-menu">
              <div class="menu-header">解决方案</div>
              <router-link to="/solutions/ecommerce" class="dropdown-item">
                <i class="fas fa-shopping-cart"></i>
                <div class="item-content">
                  <div class="item-title">电商解决方案</div>
                  <div class="item-desc">高并发、高可用的电商架构</div>
                </div>
              </router-link>
              <router-link to="/solutions/game" class="dropdown-item">
                <i class="fas fa-gamepad"></i>
                <div class="item-content">
                  <div class="item-title">游戏解决方案</div>
                  <div class="item-desc">低延迟、高性能的游戏服务</div>
                </div>
              </router-link>
              <router-link to="/solutions/video" class="dropdown-item">
                <i class="fas fa-video"></i>
                <div class="item-content">
                  <div class="item-title">视频解决方案</div>
                  <div class="item-desc">流畅、稳定的视频服务</div>
                </div>
              </router-link>
              <router-link to="/solutions/bigdata" class="dropdown-item">
                <i class="fas fa-chart-line"></i>
                <div class="item-content">
                  <div class="item-title">大数据解决方案</div>
                  <div class="item-desc">海量数据分析与处理</div>
                </div>
              </router-link>
            </div>
          </div>

          <div class="nav-item dropdown parent-menu">
            <a href="#" class="dropdown-toggle">
              <i class="fas fa-book"></i>
              开发者
              <i class="fas fa-chevron-down"></i>
            </a>
            <div class="dropdown-menu">
              <a href="#" class="dropdown-item">
                <i class="fas fa-file-alt"></i>
                开发文档
              </a>
              <a href="#" class="dropdown-item">
                <i class="fas fa-code"></i>
                API文档
              </a>
              <a href="#" class="dropdown-item">
                <i class="fas fa-tools"></i>
                开发工具
              </a>
              <a href="#" class="dropdown-item">
                <i class="fas fa-graduation-cap"></i>
                学习中心
              </a>
            </div>
          </div>

          <router-link to="/contact" class="nav-item">
            <i class="fas fa-envelope"></i>
            联系我们
          </router-link>

          <template v-if="!userStore.isLoggedIn">
            <router-link to="/auth" class="auth-btn">
              <i class="fas fa-user"></i>
              登录/注册
            </router-link>
          </template>
          <template v-else>
            <router-link to="/dashboard" class="console-btn">
              <i class="fas fa-tachometer-alt"></i>
              控制台
            </router-link>
          </template>
        </div>
        <div class="menu-toggle" @click="toggleMenu">
          <div class="hamburger" :class="{ 'active': isMenuOpen }">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </nav>
    <router-view></router-view>
  </div>
</template>

<script>
import { userStore } from './store/user'

export default {
  name: 'App',
  data() {
    return {
      userStore,
      isScrolled: false,
      isMenuOpen: false,
      showUserMenu: false
    }
  },
  computed: {
    showNavbar() {
      // 在控制台相关页面不显示导航栏
      const hideNavbarRoutes = [
        'dashboard',
        'instances',
        'orders', 
        'billing',
        'security',
        'support',
        'ticket',
        'ticket-detail',
        'verification',
        'referral',
        'ecs-instances',  // 添加实例管理子页面
        'oss-instances',
        'cdn-instances',
        'slb-instances',
        'instance-detail'
      ]
      return !hideNavbarRoutes.includes(this.$route.name)
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    userStore.checkAuth()
    document.addEventListener('click', this.closeUserMenu)
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll)
    document.removeEventListener('click', this.closeUserMenu)
  },
  methods: {
    handleScroll() {
      this.isScrolled = window.scrollY > 50
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen
    },
    toggleUserMenu(event) {
      event.stopPropagation()
      this.showUserMenu = !this.showUserMenu
    },
    closeUserMenu(event) {
      if (!this.$refs.userMenu?.contains(event.target)) {
        this.showUserMenu = false
      }
    },
    async handleLogout() {
      try {
        userStore.logout()
        this.showUserMenu = false
        if (this.$route.meta.requiresAuth) {
          this.$router.push('/')
        }
      } catch (error) {
        console.error('退出登录失败:', error)
      }
    },
    scrollToSection(sectionId) {
      this.isMenuOpen = false
      const element = document.getElementById(sectionId)
      if (element) {
        const navbarHeight = document.querySelector('.navbar').offsetHeight
        const elementPosition = element.offsetTop - navbarHeight
        window.scrollTo({
          top: elementPosition,
          behavior: 'smooth'
        })
      }
    }
  },
  watch: {
    '$route'(to) {
      if (to.path === '/') {
        this.$nextTick(() => {
          const hash = window.location.hash
          if (hash) {
            const sectionId = hash.slice(1)
            this.scrollToSection(sectionId)
          }
        })
      }
      this.isMenuOpen = false
      this.showUserMenu = false
    }
  }
}
</script>

<style>
/* 重置默认样式 */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
}

/* 主容器样式 */
.cloud-computing {
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
  background: #f8f9fa;
}

/* 导航栏样式 */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  z-index: 100;
  transition: all 0.3s ease;
}

.navbar-scrolled {
  height: 64px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

.nav-container {
  max-width: 1400px;
  margin: 0 auto;
  height: 100%;
  padding: 0 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: #2c3e50;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.logo i {
  color: #42b983;
  font-size: 1.8rem;
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.nav-item {
  text-decoration: none;
  color: #2c3e50;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  position: relative;
  transition: all 0.3s ease;
}

.nav-item::after {
  display: none;
}

.nav-item i {
  font-size: 1.1rem;
  color: #42b983;
}

.auth-btn {
  background: #42b983;
  color: white !important;
  text-decoration: none;
  padding: 0.8rem 1.5rem;
  border-radius: 25px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;
  transition: all 0.3s ease;
}

.auth-btn:hover {
  background: #3aa876;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(66, 185, 131, 0.2);
}

.menu-toggle {
  display: none;
  cursor: pointer;
}

.hamburger {
  width: 30px;
  height: 20px;
  position: relative;
}

.hamburger span {
  display: block;
  width: 100%;
  height: 2px;
  background: #2c3e50;
  position: absolute;
  transition: all 0.3s ease;
}

.hamburger span:first-child {
  top: 0;
}

.hamburger span:nth-child(2) {
  top: 9px;
}

.hamburger span:last-child {
  bottom: 0;
}

.hamburger.active span:first-child {
  transform: rotate(45deg);
  top: 9px;
}

.hamburger.active span:nth-child(2) {
  opacity: 0;
}

.hamburger.active span:last-child {
  transform: rotate(-45deg);
  bottom: 9px;
}

@media (max-width: 968px) {
  .menu-toggle {
    display: block;
  }

  .nav-links {
    position: fixed;
    top: 70px;
    left: 0;
    right: 0;
    background: white;
    flex-direction: column;
    padding: 2rem;
    gap: 1.5rem;
    transform: translateY(-150%);
    opacity: 0;
    transition: all 0.3s ease;
    box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  }

  .nav-links.nav-active {
    transform: translateY(0);
    opacity: 1;
  }

  .nav-item {
    width: 100%;
    justify-content: center;
  }

  .auth-btn {
    width: 100%;
    justify-content: center;
  }
}

/* 适配暗色模式 */
@media (prefers-color-scheme: dark) {
  .navbar {
    background-color: rgba(28, 28, 28, 0.95);
  }

  .navbar-scrolled {
    background-color: rgba(28, 28, 28, 0.98);
  }

  .logo {
    color: #fff;
  }

  .nav-item {
    color: #fff;
  }

  .hamburger span {
    background: #fff;
  }
}

.user-menu {
  position: relative;
  cursor: pointer;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 25px;
  background: rgba(66, 185, 131, 0.1);
  color: #42b983;
  transition: all 0.3s ease;
}

.user-info:hover {
  background: rgba(66, 185, 131, 0.2);
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 0.5rem;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  padding: 0.5rem 0;
  min-width: 200px;
}

.dropdown-item {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  padding: 0.8rem 1rem;
  color: #2c3e50;
  text-decoration: none;
  transition: all 0.3s ease;
}

.dropdown-item:hover {
  background: rgba(66, 185, 131, 0.1);
  color: #42b983;
}

.dropdown-divider {
  height: 1px;
  background: #eee;
  margin: 0.5rem 0;
}

/* 添加平滑滚动效果 */
html {
  scroll-behavior: smooth;
}

/* 调整锚点定位，考虑固定导航栏的高度 */
section {
  scroll-margin-top: 70px;
}

/* 优化移动端下拉菜单样式 */
@media (max-width: 968px) {
  .nav-links {
    max-height: calc(100vh - 70px);
    overflow-y: auto;
  }

  .user-menu {
    width: 100%;
  }

  .dropdown-menu {
    position: static;
    width: 100%;
    margin-top: 1rem;
    box-shadow: none;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
  }

  .dropdown-item {
    padding: 1rem;
  }
}

/* 添加下拉菜单样式 */
.dropdown {
  position: relative;
}

.dropdown-toggle {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.dropdown-toggle i:last-child {
  font-size: 0.8rem;
  transition: transform 0.3s ease;
}

.dropdown:hover .dropdown-toggle i:last-child {
  transform: rotate(180deg);
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  min-width: 200px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: all 0.3s ease;
  z-index: 1000;
}

.dropdown:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.dropdown-item {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  padding: 0.8rem 1rem;
  color: #2c3e50;
  text-decoration: none;
  transition: all 0.3s ease;
}

.dropdown-item:hover {
  background: rgba(66, 185, 131, 0.1);
  color: #42b983;
}

.dropdown-item i {
  font-size: 1.1rem;
  color: #42b983;
}

/* 移动端适配 */
@media (max-width: 968px) {
  .dropdown-menu {
    position: static;
    opacity: 1;
    visibility: visible;
    transform: none;
    box-shadow: none;
    background: rgba(0,0,0,0.05);
    margin: 0.5rem 0;
    border-radius: 4px;
  }

  .dropdown-toggle i:last-child {
    display: none;
  }
}

/* 更新父菜单样式 */
.parent-menu {
  position: relative;
}

.parent-menu .dropdown-toggle {
  padding: 0.8rem 1rem;
  border-radius: 8px;
  transition: all 0.3s ease;
  text-decoration: none;
  color: #2c3e50;
}

.parent-menu:hover .dropdown-toggle {
  background: rgba(66, 185, 131, 0.1);
  color: #42b983;
}

.arrow {
  font-size: 0.8rem;
  margin-left: 0.5rem;
  transition: transform 0.3s ease;
}

.parent-menu:hover .arrow {
  transform: rotate(180deg);
}

.menu-header {
  padding: 1rem;
  color: #666;
  font-size: 0.9rem;
  border-bottom: 1px solid #eee;
}

.dropdown-menu {
  min-width: 280px;
  padding: 0.5rem 0;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  background: white;
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: all 0.3s ease;
}

.parent-menu:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.dropdown-item {
  display: flex;
  align-items: flex-start;
  padding: 1rem;
  color: #2c3e50;
  text-decoration: none;
  transition: all 0.3s ease;
}

.dropdown-item i {
  font-size: 1.2rem;
  color: #42b983;
  margin-right: 1rem;
  margin-top: 0.2rem;
}

.item-content {
  flex: 1;
}

.item-title {
  font-weight: 500;
  margin-bottom: 0.3rem;
}

.item-desc {
  font-size: 0.9rem;
  color: #666;
}

.dropdown-item:hover {
  background: rgba(66, 185, 131, 0.05);
}

.dropdown-item:hover .item-title {
  color: #42b983;
}

/* 移动端适配 */
@media (max-width: 968px) {
  .dropdown-menu {
    position: static;
    box-shadow: none;
    opacity: 1;
    visibility: visible;
    transform: none;
    min-width: auto;
    background: rgba(0, 0, 0, 0.02);
    margin: 0.5rem 0;
  }

  .menu-header {
    display: none;
  }

  .dropdown-item {
    padding: 0.8rem;
  }

  .item-desc {
    display: none;
  }

  .arrow {
    display: none;
  }
}

/* 添加控制台按钮样式 */
.console-btn {
  background: #42b983;
  color: white !important;
  text-decoration: none;
  padding: 0.8rem 1.5rem;
  border-radius: 25px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;
  transition: all 0.3s ease;
}

.console-btn:hover {
  background: #3aa876;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(66, 185, 131, 0.2);
}

@media (max-width: 768px) {
  .nav-container {
    padding: 0 1rem;
  }
}
</style>
