export const dashboardMenuItems = [
  { name: '控制台', path: '/dashboard', icon: 'fas fa-tachometer-alt' },
  { 
    name: '实例管理', 
    path: '/instances', 
    icon: 'fas fa-server',
    children: [
      { name: '云服务器', path: '/instances/ecs', icon: 'fas fa-hdd' },
      { name: '对象存储', path: '/instances/oss', icon: 'fas fa-database' },
      { name: 'CDN加速', path: '/instances/cdn', icon: 'fas fa-network-wired' },
      { name: '负载均衡', path: '/instances/slb', icon: 'fas fa-random' }
    ]
  },
  { name: '订单管理', path: '/orders', icon: 'fas fa-shopping-cart' },
  { name: '账单中心', path: '/billing', icon: 'fas fa-credit-card' },
  { name: '安全设置', path: '/security', icon: 'fas fa-shield-alt' },
  { name: '实名认证', path: '/verification', icon: 'fas fa-id-card' },
  { name: '帮助支持', path: '/support', icon: 'fas fa-question-circle' },
  { name: '推介计划', path: '/referral', icon: 'fas fa-gift' }
] 