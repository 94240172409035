<template>
  <div class="product-detail">
    <div class="product-nav">
      <div class="nav-container">
        <div class="product-tabs">
          <div v-for="(tab, index) in tabs" 
               :key="index" 
               :class="['tab', { active: activeTab === index }]"
               @click="activeTab = index">
            {{ tab }}
          </div>
        </div>
      </div>
    </div>

    <div class="content-section" v-show="activeTab === 0">
      <div class="product-overview">
        <div class="product-card main-card">
          <h2>云服务器 ECS</h2>
          <p class="description">稳定、安全、弹性的计算服务</p>
          <div class="features-grid">
            <div class="feature">
              <i class="fas fa-tachometer-alt"></i>
              <h4>高性能</h4>
              <p>新一代CPU，高性能网络</p>
            </div>
            <div class="feature">
              <i class="fas fa-shield-alt"></i>
              <h4>安全可靠</h4>
              <p>多重安全防护，数据可靠性99.9999%</p>
            </div>
            <div class="feature">
              <i class="fas fa-sync-alt"></i>
              <h4>弹性伸缩</h4>
              <p>秒级扩容，灵活应对业务峰值</p>
            </div>
            <div class="feature">
              <i class="fas fa-clock"></i>
              <h4>即开即用</h4>
              <p>5分钟内完成部署，快速上线</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="content-section" v-show="activeTab === 1">
      <div class="config-container">
        <div class="config-section">
          <h3>实例规格</h3>
          <div class="config-options">
            <div v-for="(spec, index) in instanceSpecs" 
                 :key="index"
                 :class="['config-option', { active: selectedSpec === index }]"
                 @click="selectSpec(index)">
              <div class="spec-header">
                <h4>{{ spec.name }}</h4>
                <span class="price">¥{{ spec.price }}/月</span>
              </div>
              <div class="spec-details">
                <div class="spec-item">
                  <i class="fas fa-microchip"></i>
                  <span>{{ spec.cpu }}核 CPU</span>
                </div>
                <div class="spec-item">
                  <i class="fas fa-memory"></i>
                  <span>{{ spec.memory }}GB 内存</span>
                </div>
                <div class="spec-item">
                  <i class="fas fa-network-wired"></i>
                  <span>{{ spec.bandwidth }}Mbps 带宽</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="config-section">
          <h3>存储选项</h3>
          <div class="storage-slider">
            <div class="slider-header">
              <span>系统盘大小</span>
              <span class="storage-value">{{ storageSize }}GB</span>
            </div>
            <input type="range" 
                   v-model="storageSize" 
                   min="40" 
                   max="500" 
                   step="10"
                   class="range-slider">
            <div class="slider-marks">
              <span>40GB</span>
              <span>500GB</span>
            </div>
          </div>
        </div>

        <div class="config-section">
          <h3>操作系统</h3>
          <div class="os-options">
            <div v-for="(os, index) in operatingSystems"
                 :key="index"
                 :class="['os-option', { active: selectedOS === index }]"
                 @click="selectedOS = index">
              <i :class="os.icon"></i>
              <span>{{ os.name }}</span>
            </div>
          </div>
        </div>

        <div class="config-summary">
          <div class="summary-details">
            <h3>配置摘要</h3>
            <div class="summary-items">
              <div class="summary-item">
                <span>实例规格</span>
                <span>{{ instanceSpecs[selectedSpec].name }}</span>
              </div>
              <div class="summary-item">
                <span>系统盘</span>
                <span>{{ storageSize }}GB</span>
              </div>
              <div class="summary-item">
                <span>操作系统</span>
                <span>{{ operatingSystems[selectedOS].name }}</span>
              </div>
              <div class="summary-item total">
                <span>预估总价</span>
                <span class="total-price">¥{{ calculateTotal() }}/月</span>
              </div>
            </div>
          </div>
          <button class="purchase-btn" @click="handlePurchase">立即购买</button>
        </div>
      </div>
    </div>

    <div class="content-section" v-show="activeTab === 2">
      <div class="tech-specs">
        <div class="spec-group" v-for="(group, index) in specifications" :key="index">
          <h3>{{ group.title }}</h3>
          <div class="spec-table">
            <div class="spec-row" v-for="(spec, sIndex) in group.specs" :key="sIndex">
              <div class="spec-label">{{ spec.label }}</div>
              <div class="spec-value">{{ spec.value }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductDetailView',
  data() {
    return {
      activeTab: 1,
      tabs: ['产品概览', '规格选择', '技术规格'],
      selectedSpec: 0,
      storageSize: 40,
      selectedOS: 0,
      instanceSpecs: [
        {
          name: '基础型',
          price: 99,
          cpu: 2,
          memory: 4,
          bandwidth: 1
        },
        {
          name: '通用型',
          price: 199,
          cpu: 4,
          memory: 8,
          bandwidth: 5
        },
        {
          name: '企业型',
          price: 399,
          cpu: 8,
          memory: 16,
          bandwidth: 10
        },
        {
          name: '性能型',
          price: 699,
          cpu: 16,
          memory: 32,
          bandwidth: 20
        }
      ],
      operatingSystems: [
        { name: 'CentOS 7.9', icon: 'fab fa-linux' },
        { name: 'Ubuntu 20.04', icon: 'fab fa-ubuntu' },
        { name: 'Windows Server 2019', icon: 'fab fa-windows' },
        { name: 'Debian 10', icon: 'fab fa-debian' }
      ],
      specifications: [
        {
          title: '计算规格',
          specs: [
            { label: 'CPU型号', value: 'Intel Xeon Platinum 8269CY' },
            { label: '内存类型', value: 'DDR4' },
            { label: '处理器主频', value: '2.5 GHz - 3.2 GHz' }
          ]
        },
        {
          title: '存储规格',
          specs: [
            { label: '系统盘', value: '40GB - 500GB SSD' },
            { label: '数据盘', value: '最高支持16TB' },
            { label: 'IOPS', value: '最高50000' }
          ]
        },
        {
          title: '网络规格',
          specs: [
            { label: '网络类型', value: '支持专有网络VPC' },
            { label: '带宽', value: '最高100Mbps' },
            { label: '网络延迟', value: '<1ms' }
          ]
        }
      ]
    }
  },
  methods: {
    selectSpec(index) {
      this.selectedSpec = index
    },
    calculateTotal() {
      const specPrice = this.instanceSpecs[this.selectedSpec].price
      const storagePrice = Math.floor(this.storageSize / 10) * 2
      return specPrice + storagePrice
    },
    handlePurchase() {
      const config = {
        spec: this.instanceSpecs[this.selectedSpec],
        storage: this.storageSize,
        os: this.operatingSystems[this.selectedOS],
        total: this.calculateTotal()
      }
      console.log('购买配置:', config)
      // TODO: 实现购买逻辑
    }
  }
}
</script>

<style scoped>
.product-detail {
  min-height: 100vh;
  background: #f8f9fa;
}

.product-nav {
  background: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  position: sticky;
  top: 0;
  z-index: 100;
}

.nav-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.product-tabs {
  display: flex;
  gap: 2rem;
}

.tab {
  padding: 1rem 0;
  cursor: pointer;
  position: relative;
  color: #666;
}

.tab.active {
  color: #42b983;
}

.tab.active::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: #42b983;
}

.content-section {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 0 20px;
}

.product-overview {
  display: grid;
  gap: 2rem;
}

.main-card {
  background: white;
  padding: 3rem;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0,0,0,0.05);
  text-align: center;
}

.main-card h2 {
  color: #2c3e50;
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.description {
  color: #666;
  font-size: 1.2rem;
  margin-bottom: 3rem;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  margin-bottom: 3rem;
}

.feature {
  padding: 1.5rem;
  background: #f8f9fa;
  border-radius: 15px;
  transition: all 0.3s ease;
}

.feature:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0,0,0,0.05);
}

.feature i {
  font-size: 2rem;
  color: #42b983;
  margin-bottom: 1rem;
}

.feature h4 {
  color: #2c3e50;
  margin-bottom: 0.5rem;
}

.feature p {
  color: #666;
  font-size: 0.9rem;
}

.try-btn {
  background: #42b983;
  color: white;
  border: none;
  padding: 1rem 3rem;
  border-radius: 30px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.try-btn:hover {
  background: #3aa876;
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(66, 185, 131, 0.3);
}

.pricing-plans {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.plan-card {
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0,0,0,0.05);
}

.plan-header {
  text-align: center;
  margin-bottom: 2rem;
}

.plan-header h3 {
  color: #2c3e50;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.price {
  color: #42b983;
}

.amount {
  font-size: 2.5rem;
  font-weight: bold;
}

.period {
  color: #666;
}

.plan-features {
  list-style: none;
  padding: 0;
  margin: 0 0 2rem 0;
}

.plan-features li {
  padding: 0.8rem 0;
  color: #666;
  display: flex;
  align-items: center;
}

.plan-features i {
  color: #42b983;
  margin-right: 1rem;
}

.select-plan {
  width: 100%;
  padding: 1rem;
  background: transparent;
  border: 2px solid #42b983;
  color: #42b983;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.select-plan:hover {
  background: #42b983;
  color: white;
}

.tech-specs {
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0,0,0,0.05);
}

.spec-group {
  margin-bottom: 2rem;
}

.spec-group h3 {
  color: #2c3e50;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #f0f0f0;
}

.spec-row {
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 1rem 0;
  border-bottom: 1px solid #f0f0f0;
}

.spec-label {
  color: #666;
  font-weight: 500;
}

.spec-value {
  color: #2c3e50;
}

@media (max-width: 768px) {
  .product-tabs {
    justify-content: space-between;
    gap: 1rem;
  }
  
  .features-grid {
    grid-template-columns: 1fr;
  }
  
  .pricing-plans {
    grid-template-columns: 1fr;
  }
  
  .spec-row {
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }
}

.config-container {
  background: white;
  border-radius: 20px;
  padding: 2rem;
  box-shadow: 0 10px 30px rgba(0,0,0,0.05);
}

.config-section {
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #eee;
}

.config-section h3 {
  color: #2c3e50;
  margin-bottom: 1.5rem;
}

.config-options {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
}

.config-option {
  border: 2px solid #eee;
  border-radius: 12px;
  padding: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.config-option.active {
  border-color: #42b983;
  box-shadow: 0 5px 15px rgba(66, 185, 131, 0.1);
}

.spec-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.spec-header h4 {
  color: #2c3e50;
  margin: 0;
}

.price {
  color: #42b983;
  font-weight: bold;
}

.spec-details {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.spec-item {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  color: #666;
}

.spec-item i {
  color: #42b983;
}

.storage-slider {
  padding: 0 1rem;
}

.slider-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  color: #2c3e50;
}

.storage-value {
  color: #42b983;
  font-weight: bold;
}

.range-slider {
  width: 100%;
  height: 4px;
  background: #eee;
  border-radius: 2px;
  outline: none;
  -webkit-appearance: none;
}

.range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  background: #42b983;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
}

.range-slider::-webkit-slider-thumb:hover {
  transform: scale(1.1);
}

.slider-marks {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  color: #666;
  font-size: 0.9rem;
}

.os-options {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
}

.os-option {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border: 2px solid #eee;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.os-option.active {
  border-color: #42b983;
  background: rgba(66, 185, 131, 0.1);
}

.os-option i {
  font-size: 1.5rem;
  color: #42b983;
}

.config-summary {
  background: #f8f9fa;
  border-radius: 12px;
  padding: 2rem;
  margin-top: 2rem;
}

.summary-items {
  display: grid;
  gap: 1rem;
  margin-top: 1rem;
}

.summary-item {
  display: flex;
  justify-content: space-between;
  color: #666;
}

.summary-item.total {
  border-top: 1px solid #eee;
  padding-top: 1rem;
  margin-top: 1rem;
  color: #2c3e50;
  font-weight: bold;
}

.total-price {
  color: #42b983;
  font-size: 1.2rem;
}

.purchase-btn {
  width: 100%;
  margin-top: 1.5rem;
  padding: 1rem;
  background: #42b983;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.purchase-btn:hover {
  background: #3aa876;
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(66, 185, 131, 0.3);
}

@media (max-width: 768px) {
  .config-container {
    padding: 1rem;
  }
  
  .config-options,
  .os-options {
    grid-template-columns: 1fr;
  }
  
  .config-summary {
    padding: 1rem;
  }
}
</style> 