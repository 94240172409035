<template>
  <div class="dashboard-header">
    <div class="header-left">
      <slot name="left"></slot>
      <h2>{{ title }}</h2>
    </div>
    <div class="header-right">
      <div class="search-box">
        <i class="fas fa-search"></i>
        <input type="text" placeholder="搜索...">
      </div>
      <div class="notifications">
        <i class="fas fa-bell"></i>
        <span class="badge">3</span>
      </div>
      <div class="user-menu" @click="toggleUserMenu" ref="userMenu">
        <div class="user-info">
          <div class="user-avatar">
            <i class="fas fa-user"></i>
          </div>
          <span class="user-name">{{ userStore.userInfo?.name || '未登录' }}</span>
          <i class="fas fa-chevron-down" :class="{ 'rotated': showUserMenu }"></i>
        </div>
        <div class="dropdown-menu" v-show="showUserMenu">
          <div class="menu-header">
            <div class="menu-user">
              <div class="menu-avatar">
                <i class="fas fa-user"></i>
              </div>
              <div class="menu-user-info">
                <strong>{{ userStore.userInfo?.name || '未登录' }}</strong>
                <small>{{ userStore.userInfo?.email || '' }}</small>
              </div>
            </div>
          </div>
          <div class="menu-items">
            <router-link to="/security" class="menu-item">
              <i class="fas fa-user-cog"></i>
              账号设置
            </router-link>
            <router-link to="/billing" class="menu-item">
              <i class="fas fa-wallet"></i>
              我的余额
            </router-link>
            <router-link to="/verification" class="menu-item">
              <i class="fas fa-id-card"></i>
              实名认证
            </router-link>
          </div>
          <div class="menu-footer">
            <a href="#" class="menu-item" @click.prevent="handleLogout">
              <i class="fas fa-sign-out-alt"></i>
              退出登录
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { userStore } from '../store/user'

export default {
  name: 'DashboardHeader',
  props: {
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      userStore,
      showUserMenu: false
    }
  },
  mounted() {
    document.addEventListener('click', this.closeUserMenu)
  },
  unmounted() {
    document.removeEventListener('click', this.closeUserMenu)
  },
  methods: {
    toggleUserMenu(event) {
      event.stopPropagation()
      this.showUserMenu = !this.showUserMenu
    },
    closeUserMenu(event) {
      if (!this.$refs.userMenu?.contains(event.target)) {
        this.showUserMenu = false
      }
    },
    async handleLogout() {
      try {
        await userStore.logout()
        this.showUserMenu = false
        this.$router.push('/auth')
      } catch (error) {
        console.error('退出登录失败:', error)
      }
    }
  }
}
</script>

<style scoped>
.dashboard-header {
  background: white;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;
  height: 40px;
}

.header-left {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.menu-toggle {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  padding: 0.5rem;
  display: none;
}

.header-right {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.search-box {
  position: relative;
}

.search-box input {
  padding: 0.5rem 1rem 0.5rem 2.5rem;
  border: 1px solid #eee;
  border-radius: 25px;
  outline: none;
  width: 200px;
  transition: all 0.3s ease;
}

.search-box i {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
}

.notifications {
  position: relative;
  padding: 0.5rem;
  cursor: pointer;
}

.badge {
  position: absolute;
  top: 0;
  right: 0;
  background: #ff4444;
  color: white;
  font-size: 0.7rem;
  padding: 0.2rem 0.4rem;
  border-radius: 10px;
}

.user-menu {
  position: relative;
  cursor: pointer;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  padding: 0.5rem 1rem;
  border-radius: 25px;
  background: rgba(66, 185, 131, 0.1);
  transition: all 0.3s ease;
}

.user-info:hover {
  background: rgba(66, 185, 131, 0.2);
}

.user-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-avatar i {
  font-size: 1.1rem;
  color: #42b983;
}

.user-name {
  color: #2c3e50;
  font-weight: 500;
}

.user-info i.fa-chevron-down {
  font-size: 0.8rem;
  color: #666;
  transition: transform 0.3s ease;
}

.user-info i.rotated {
  transform: rotate(180deg);
}

.dropdown-menu {
  position: absolute;
  top: calc(100% + 0.5rem);
  right: 0;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0,0,0,0.15);
  min-width: 240px;
  z-index: 1000;
  animation: slideIn 0.2s ease;
}

.menu-header {
  padding: 1rem;
  border-bottom: 1px solid #eee;
}

.menu-user {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.menu-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(66, 185, 131, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-avatar i {
  font-size: 1.2rem;
  color: #42b983;
}

.menu-user-info {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.menu-user-info strong {
  color: #2c3e50;
}

.menu-user-info small {
  color: #666;
  font-size: 0.85rem;
}

.menu-items {
  padding: 0.5rem 0;
}

.menu-item {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  padding: 0.8rem 1rem;
  color: #2c3e50;
  text-decoration: none;
  transition: all 0.3s ease;
}

.menu-item:hover {
  background: rgba(66, 185, 131, 0.1);
  color: #42b983;
}

.menu-item i {
  font-size: 1rem;
  width: 20px;
  text-align: center;
}

.menu-footer {
  padding: 0.5rem 0;
  border-top: 1px solid #eee;
}

.menu-footer .menu-item {
  color: #ff4444;
}

.menu-footer .menu-item:hover {
  background: rgba(255, 68, 68, 0.1);
  color: #ff4444;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .menu-toggle {
    display: block;
  }

  .search-box {
    display: none;
  }

  .user-name {
    display: none;
  }

  .user-info {
    padding: 0.5rem;
  }

  .dropdown-menu {
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 12px 12px 0 0;
    animation: slideUp 0.3s ease;
  }
}
</style> 